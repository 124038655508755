<script setup lang="ts">
const { isMobile } = useBreakpoint()

const sizeImage = computed(() => {
  const sizes = isMobile.value
    ? { height: '292', width: '390' }
    : { height: '374', width: '536' }

  return sizes
})
</script>

<template>
  <div class="bg-primary-100 lg:px-4 lg:py-16">
    <div class="lg:mx-auto lg:max-w-container-xl">
      <BaseNewsletter :size-image="sizeImage" source="block_lp" />
    </div>
  </div>
</template>

<style scoped>
@screen md {
  :deep(.newsletter-form) {
    @apply flex w-full;
  }
  :deep(.newsletter-form > div:first-child) {
    @apply w-full;
  }
  :deep(.base-input),
  :deep(.base-input input) {
    @apply rounded;
  }
  :deep(.container-button-class) {
    @apply mt-0 flex-none;
  }
  :deep(.base-form-button) {
    @apply h-[51px] text-md -ml-2;
  }
}
</style>
